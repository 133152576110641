'use client';

import { Button } from 'antd';

export default function Custom404() {
  return (
    <div className="text-center absolute left-0 top-0 w-full h-screen bg-error">
      <div className="text-center ">
        {/* <a href="https://enti.ee">
          <img
            src="https://enti.ee/logo-enti-cuadrado.jpg"
            width="200"
            height="200"
            alt="ENTi"
          />
        </a> */}
        <div className="w-full pt-5" style={{ paddingRight: 200 }}>
          <h1
            className="m-0 mb-1"
            style={{
              fontSize: 100,
              color: 'white',
              // textDecoration: 'underline wavy red',
            }}
          >
            404
          </h1>
          <p className="text-white text-4xl flex gap-3 text-center items-center justify-center">
            <Button
              onClick={() => location.replace('https://enti.ee')}
              type="primary"
            >
              Ir a inicio
            </Button>
            <Button onClick={() => history.go(-1)}>Volver</Button>
          </p>
        </div>
      </div>

      <img src="https://enti.ee/404.webp" alt="404" />
    </div>
  );
}
